import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, EMPTY, Observable, throwError } from "rxjs";
import { atmetaxAuthLink } from "../core.module";

@Injectable()
export class HttpAuthErrorInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        /*
         * Forbidden || Unauthorized
         */
        if (this.isAuthError(error)) {
          console.error("http auth error", error);
          window.location.href = atmetaxAuthLink;
          // Stop further processing by returning EMPTY
          return EMPTY;
        }
        return throwError(() => error);
      }),
    );
  }

  private isAuthError(error: HttpErrorResponse) {
    return (
      error.status === HttpStatusCode.Unauthorized ||
      error.status === HttpStatusCode.Forbidden
    );
  }
}
