import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpAuthErrorInterceptor } from "./http-auth-error.interceptor";
import { HttpErrorInterceptor } from "./http-error.interceptor";

/**
 * interception order is very important.
 * If you provide interceptors A, then B, then C, requests flow in A->B->C and responses flow out C->B->A.
 * https://v17.angular.io/guide/http-intercept-requests-and-responses#interceptor-order */
export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
  // we want the last interceptor to be HttpAuthErrorInterceptor,
  // so response is first handled by this and if needed it can handled by subsequent HttpErrorInterceptor
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpAuthErrorInterceptor,
    multi: true,
  },
];
