import { Injectable } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarDismiss,
} from "@angular/material/snack-bar";
import { Observable, Subject } from "rxjs";
import { concatMap } from "rxjs/operators";

export enum OperationType {
  SAVE = "Save",
  UPDATE = "Update",
  REORDER = "Reorder",
}

@Injectable({
  providedIn: "root",
})
export class SnackBarService {
  /**
   * A queue of snackbar messages to display sequentially, ensuring that
   * each snackbar waits for the previous one to dismiss before showing.
   */
  private snackBarQueue = new Subject<{
    message: string;
    action: string;
    config: MatSnackBarConfig;
  }>();

  /**
   * Initializes the `SnackBarService` and sets up the queue to process
   * snackbar messages sequentially using `concatMap`.
   *
   * @param snackBar Angular Material's `MatSnackBar` service, injected to
   * display snackbar notifications.
   */
  constructor(private snackBar: MatSnackBar) {
    this.snackBarQueue
      .pipe(
        concatMap(({ message, action, config }) =>
          this.showSnackbar(message, action, config),
        ),
      )
      .subscribe();
  }

  /**
   * Displays a snackbar notification and returns an observable that completes
   * once the snackbar is dismissed.
   *
   * @param message The message to display in the snackbar.
   * @param action Optional action label to display as a button in the snackbar.
   * @param config Configuration options for the snackbar, such as duration
   * and panel class.
   *
   * @returns An observable that completes when the snackbar is dismissed.
   */
  private showSnackbar(
    message: string,
    action: string,
    config: MatSnackBarConfig,
  ): Observable<MatSnackBarDismiss> {
    const snackBarRef = this.snackBar.open(message, action, config);
    return snackBarRef.afterDismissed();
  }

  success(
    message: OperationType | string,
    action = "",
    config: MatSnackBarConfig = {
      duration: 3000,
      horizontalPosition: "end",
      panelClass: ["snackbar-success"],
    },
  ): void {
    if ((Object.values(OperationType) as string[]).includes(message))
      message = `${message} succeeded.`;
    this.snackBarQueue.next({ message, action, config });
  }

  error(
    message: string,
    action = "",
    config: MatSnackBarConfig = {
      duration: 3000,
      horizontalPosition: "end",
      panelClass: ["snackbar-error"],
    },
  ): void {
    this.snackBarQueue.next({ message, action, config });
  }

  warn(
    message: string,
    action = "",
    config: MatSnackBarConfig = {
      duration: 3000,
      horizontalPosition: "end",
      panelClass: ["snackbar-warn"],
    },
  ): void {
    this.snackBarQueue.next({ message, action, config });
  }

  info(
    message: string,
    action = "",
    config: MatSnackBarConfig = {
      duration: 3000,
      horizontalPosition: "end",
      panelClass: ["snackbar-info"],
    },
  ): void {
    this.snackBarQueue.next({ message, action, config });
  }
}
